import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

function ContactUs({ simple }) {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      contact: contentfulHomePage {
        contactTitle
        contactSubtitle
        contactDescription
      }
    }
  `)

  const { contactTitle, contactSubtitle, contactDescription } = data.contact

  return (
    <div className="relative z-10">
      <div
        className={`max-w-6xl mx-auto px-4 md:px-8 xl:px-0 ContactUs md:rounded-lg ${
          simple && "mb-8"
        }`}
        style={{ marginTop: simple && "0" }}
      >
        <div className="md:flex md:justify-around md:items-center py-8 md:px-12">
          <div className="text-white md:w-3/4">
            <h1 className="text-3xl font-bold siteFont">{contactTitle}</h1>
            <p className="font-bold text-lg my-4">{contactSubtitle}</p>
            <p className="text-lg">{contactDescription}</p>
          </div>
          <Link
            to="/contact"
            className="btn text-center inline-block uppercase rounded-full text-xl font-bold w-auto md:w-1/5 bg-white focus:outline-none mt-8 md:mt-0 py-4 px-8 siteFont"
          >
            Get Started
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
