import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  FacebookShareCount,
  PinterestShareCount,
} from "react-share"

import { FacebookIcon, TwitterIcon, LinkedIn, PinterestIcon } from "../../icons"

const SocialMediaPanel = ({ data: { slug, coverImage } }) => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      social: site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const { siteUrl } = data.social.siteMetadata

  const shareUrl = `${siteUrl}/blog/${slug}`
  return (
    <div className="container max-w-5xl px-4 mx-auto mb-8 text-white social md:px-8 xl:px-0 md:mb-16">
      <div className="flex flex-col items-center justify-center px-4 py-8 rounded-lg bg-greyBG md:flex-row">
        <h3 className="text-2xl md:mr-8 siteFont">Share this article:</h3>
        <div className="flex">
          <FacebookShareButton url={shareUrl} className="btn facebook">
            <FacebookIcon width="18" height="18" className="md:mr-2" />
            <span className="hidden md:inline-block">
              Facebook &nbsp;&nbsp;
            </span>
            <FacebookShareCount
              className="hidden md:inline-block"
              url={shareUrl}
            >
              {shareCount => <span>{shareCount}</span>}
            </FacebookShareCount>
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} className="btn twitter">
            <TwitterIcon width="18" height="18" className="md:mr-2" />
            <span className="hidden md:inline-block">Twitter</span>
          </TwitterShareButton>
          <LinkedinShareButton url={shareUrl} className="btn linkedin">
            <LinkedIn width="18" height="18" className="md:mr-2" />
            <span className="hidden md:inline-block">LinkedIn</span>
          </LinkedinShareButton>
          <PinterestShareButton
            url={shareUrl}
            className="btn pinterest"
            media={coverImage.fluid.src}
          >
            <PinterestIcon width="18" height="18" className="md:mr-2" />
            <span className="hidden md:inline-block">
              Pinterest &nbsp;&nbsp;
            </span>

            <PinterestShareCount
              className="hidden md:inline-block"
              url={shareUrl}
            >
              {shareCount => <span>{shareCount}</span>}
            </PinterestShareCount>
          </PinterestShareButton>
        </div>
      </div>
    </div>
  )
}

export default SocialMediaPanel
