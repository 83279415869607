import React, { useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

// Components
import Banner from "../components/Templates/Content/banner"
import TopicContent from "../components/Templates/Content/topicContent"
import ContactUs from "../components/contactUs"
import SocialMediaPanel from "../components/Templates/Content/socialMediaPanel"
import DownloadableMediaModel from "../components/Templates/Content/downloadableMediaModel"

// Context API data
import { AppContext } from "../store/AppContext"

const Content = ({ data }) => {
  const globalData = useContext(AppContext)
  const {
    slug,
    ogTitle,
    ogDescription,
    title,
    heading,
    publishedDate,
    categories,
    coverImage,
    pageContent,
    downloadableMedia,
    author,
  } = data.content
  const bannerData = {
    title,
    heading,
    publishedDate,
    categories,
    coverImage,
    author: author?.name || false,
  }
  return (
    <Layout>
      <SEO
        title={ogTitle || title}
        description={ogDescription}
        image={coverImage?.fluid?.src}
      />
      <Banner data={bannerData} customNav={globalData?.state?.customNav} />
      <TopicContent data={pageContent} author={author} />
      {downloadableMedia && <DownloadableMediaModel data={downloadableMedia} />}
      {slug !== "privacy-policy" && (
        <SocialMediaPanel data={{ slug, coverImage }} />
      )}
      <ContactUs simple />
    </Layout>
  )
}

export const query = graphql`
  query ContentPageQuery($slug: String) {
    content: contentfulContentPage(slug: { eq: $slug }) {
      slug
      ogTitle
      ogDescription
      heading
      title
      coverImage {
        title
        fluid(quality: 100) {
          src
        }
      }
      publishedDate
      categories
      pageContent {
        json
      }
      downloadableMedia {
        title
        description {
          json
        }
        mediaAsset {
          file {
            url
          }
        }
      }
      author {
        shortBio {
          json
        }
        name
        image {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`

export default Content
