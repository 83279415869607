import React from "react"
import { Link } from "gatsby"
import Background from "../../backgroundImage"

const Banner = ({
  data: { title, heading, publishedDate, categories, coverImage, author },
  customNav,
}) => {
  const date = new Date(publishedDate)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  return (
    <Background className={`text-white ${customNav ? "pt-4 md:pt-8" : ""}`}>
      <div className="container flex flex-col justify-center max-w-4xl px-4 pt-16 mx-auto md:pt-20 md:px-8 xl:px-0 md:items-center">
        <p className="text-lg font-bold md:text-xl siteFont">{heading}</p>
        <h1 className="my-2 text-3xl font-bold uppercase md:text-5xl md:text-center siteFont">
          {title}
        </h1>
        {}
        {publishedDate && (
          <p className="text-xs font-bold Gray md:text-sm">
            {author && (
              <>
                <span className="font-normal tracking-wide">By: {author}</span>
                &nbsp;&nbsp;&nbsp;{" "}
              </>
            )}
            {`${
              months[date.getMonth()]
            } ${date.getDate()}, ${date.getFullYear()}`}{" "}
            &nbsp;&nbsp;&nbsp;{" "}
            {categories.map((category, index) => (
              <Link
                to={`/blog`}
                state={{ category: category }}
                key={index}
                className="block font-normal tracking-wide uppercase md:inline"
              >
                {`${category}${index + 1 !== categories.length ? ", " : ""}`}
              </Link>
            ))}
          </p>
        )}
        {coverImage && (
          <img
            className="w-full mt-8 border-lg"
            src={coverImage.fluid.src}
            alt={coverImage.title}
            loading="eager"
          />
        )}
      </div>
    </Background>
  )
}

export default Banner
