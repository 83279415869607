import React from "react"

// Component
import Background from "../../backgroundImage"
import { PDF } from "../../icons"
import RichTextRenderer from "../../RichTextRenderer"

const DownloadableMediaModel = ({
  data: {
    title,
    description,
    mediaAsset: { file },
  },
  content,
}) => {
  return (
    <Background
      type="blue"
      style={{ backgroundSize: "cover" }}
      className="max-w-5xl px-4 py-6 mx-auto mb-4 download-media rounded-xl md:px-8 xl:px-0 md:py-10 md:my-6"
    >
      <div className="mx-auto text-center text-white md:w-10/12">
        {content ? (
          <div className="items-center justify-between md:flex">
            <h3 className="mb-3 text-3xl font-bold leading-normal capitalize siteFont md:text-4xl">
              {title}
            </h3>
            <button>
              <a
                className="flex items-center font-bold siteFont"
                href={file.url}
                target="_blank"
              >
                <div className="flex items-center siteFont">
                  <PDF className="mr-2" />
                  <span>Download</span>
                </div>
              </a>
            </button>
          </div>
        ) : (
          <>
            <h3 className="mb-3 text-3xl font-bold leading-normal capitalize siteFont md:text-4xl">
              {title}
            </h3>
            {description && (
              <div className="mb-6">
                {RichTextRenderer(description.json || description)}
              </div>
            )}
            <button>
              <a
                className="flex items-center font-bold siteFont"
                href={file.url}
                target="_blank"
              >
                <div className="flex items-center siteFont">
                  <PDF className="mr-2" />
                  <span>Download</span>
                </div>
              </a>
            </button>
          </>
        )}
      </div>
    </Background>
  )
}

export default DownloadableMediaModel
