import React from "react"
import Img from "gatsby-image"

// Components
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Quote } from "../../icons"
import DownloadableMediaModel from "./downloadableMediaModel"

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="my-4 text-3xl font-bold text-white siteFont md:text-5xl">
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="my-4 text-xl font-bold text-white siteFont md:text-4xl">
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="my-4 text-lg font-bold text-white siteFont md:text-3xl">
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className="my-4 text-base font-bold text-white siteFont md:text-xl">
        {children}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5 className="my-4 font-bold text-white siteFont md:text-lg">
        {children}
      </h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6 className="my-4 font-bold text-white siteFont md:text-lg">
        {children}
      </h6>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="my-4 md:text-lg">{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="pl-6 list-disc list-outsite md:pl-12">{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ul className="pl-6 list-decimal list-outsite md:pl-12">{children}</ul>
    ),
    [BLOCKS.HR]: () => <hr className="my-6 md:my-16 xl:mx-auto" />,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
      <img
        className="h-auto mx-auto my-8 rounded-lg shadow-xl"
        src={node?.data?.target?.fields?.file["en-US"]?.url}
        alt={`${node?.data?.target?.fields?.title["en-US"]}`}
      />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      if (node?.data?.target?.fields?.videoEmbedUrl) {
        return (
          <iframe
            title={node?.data?.target?.fields?.title["en-US"]}
            src={node?.data?.target?.fields?.videoEmbedUrl["en-US"]}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )
      } else {
        const title = node?.data?.target?.fields?.title["en-US"]
        const description = node?.data?.target?.fields?.description
        const mediaAsset = {
          file: {
            url:
              node?.data?.target?.fields?.mediaAsset["en-US"].fields.file[
                "en-US"
              ]?.url,
          },
        }

        const pdfData = { title, description, mediaAsset }

        return <DownloadableMediaModel content={true} data={pdfData} />
      }
    },
    [BLOCKS.QUOTE]: (node, children) => (
      <div className="flex flex-col items-start justify-between px-2 py-6 my-6 rounded-lg bg-greyBG md:px-4 md:py-8 md:flex-row">
        <div className="w-full md:w-32">
          <Quote className="mb-2 md:mb-0 md:mr-4" />
        </div>
        <div>
          {children.map((node, index) => (
            <h4
              key={index}
              className="w-full mt-2 text-base font-bold text-white siteFont md:pr-2 md:text-2xl"
            >
              {node.props.children}
            </h4>
          ))}
        </div>
      </div>
    ),
    [INLINES.HYPERLINK]: node => {
      const { content, data } = node
      return (
        <a
          className="text-blue-500 cursor-pointer hover:underline hover:text-blue-700"
          href={data.uri}
          target="_blank"
          rel="noreferrer"
        >
          {content[0].value}
        </a>
      )
    },
  },
  renderMark: {
    [MARKS.UNDERLINE]: text => <u>{text}</u>,
  },
}

const AuthorSection = ({ data: { image, shortBio } }) => (
  <div className="container max-w-5xl px-4 mx-auto mb-6 authorSection md:px-8 md:mb-20">
    <div className="p-4 rounded-lg md:flex md:p-6 bg-greyBG">
      <div className="w-32 md:mr-8">
        <Img fluid={image.fluid} alt={image.title} className="w-full" />
      </div>
      <div className="w-full">
        <h3 className="text-2xl text-white siteFont">About the Author</h3>
        <div className="Gray">
          {documentToReactComponents(shortBio.json, options)}
        </div>
      </div>
    </div>
  </div>
)

const TopicContent = ({ data, author }) => {
  return (
    <div>
      <div className="container max-w-4xl px-4 py-10 mx-auto contentPage md:px-8 xl:px-0 Gray">
        {documentToReactComponents(data.json, options)}
      </div>
      {author && <AuthorSection data={author} />}
    </div>
  )
}

export default TopicContent
